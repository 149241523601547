// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  .footer-container{
    display: flex;
    justify-content: space-between;
    max-width: 800px;
    margin: 0 auto;
  }

  .footer-link {
    color: #fff;
    text-decoration: none;
    font-size: 16px;
  }
  .footer-link:hover {
    text-decoration: underline;
  }
  @media(max-width:1300px) {
    .footer-link {
      font-size: 12px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/Footer.css"],"names":[],"mappings":";EACE;IACE,aAAa;IACb,8BAA8B;IAC9B,gBAAgB;IAChB,cAAc;EAChB;;EAEA;IACE,WAAW;IACX,qBAAqB;IACrB,eAAe;EACjB;EACA;IACE,0BAA0B;EAC5B;EACA;IACE;MACE,eAAe;IACjB;EACF","sourcesContent":["\n  .footer-container{\n    display: flex;\n    justify-content: space-between;\n    max-width: 800px;\n    margin: 0 auto;\n  }\n\n  .footer-link {\n    color: #fff;\n    text-decoration: none;\n    font-size: 16px;\n  }\n  .footer-link:hover {\n    text-decoration: underline;\n  }\n  @media(max-width:1300px) {\n    .footer-link {\n      font-size: 12px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
