import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { postFeedback } from '../service/apiServices';
import { useAzureData } from '../context/AzureContext';

const Feedback = (props: any) => {
  const [feedback, setFeedback] = useState({
    dashboardDecisionImpact: '',
    featureChangeSuggestion: '',
    userExperienceFeedback: ''
  });
  const [alertMessage, setAlertMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const { apiToken } = useAzureData();
  useEffect(() => {
    clearSetValue();
  }, [props.closeModal]);

  const clearSetValue = () => {
    setAlertMessage('');
    setFeedback({
      dashboardDecisionImpact: '',
      featureChangeSuggestion: '',
      userExperienceFeedback: ''
    });
    setIsChecked(false);
  };

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (
      feedback.dashboardDecisionImpact.trim() === '' &&
      feedback.featureChangeSuggestion.trim() === '' &&
      feedback.userExperienceFeedback.trim() === ''
    ) {
      setAlertMessage('Feedback cannot be empty');
      return;
    }

    const result = await postFeedback(
      {
        dashboard_decision_impact: feedback.dashboardDecisionImpact,
        feature_change_suggestion: feedback.featureChangeSuggestion,
        user_experience_feedback: feedback.userExperienceFeedback
      },
      apiToken
    );
    setSuccessMessage(result.message);
    props.closeModal();
  };

  const handleCheck = (event: any) => {
    setIsChecked(event.target.checked);
  };

  return (
    <Modal show={props.show} onHide={props.closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Survey</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {alertMessage && (
          <div className="text-center" style={{ color: '#FF2828' }}>
            {alertMessage}
          </div>
        )}
        {successMessage && (
          <div className="text-center" style={{ color: '#61D4C4' }}>
            {successMessage}
          </div>
        )}

        <form onSubmit={handleSubmit} id="feedbackform">
          <div className="mb-3">
            <p className="mb-1 unl-label-p">
              Can you tell us about a time when information from our dashboard facilitated business
              decisions?
            </p>
            <textarea
              className="w-100 form-control"
              name="feedback1"
              onChange={(event) =>
                setFeedback((prev) => ({ ...prev, dashboardDecisionImpact: event.target.value }))
              }></textarea>
          </div>
          <div className="mb-3">
            <p className="mb-1 unl-label-p">
              What one feature would you add or change on our dashboard? Please explain why.
            </p>
            <textarea
              className="w-100 form-control"
              name="feedback2"
              onChange={(event) =>
                setFeedback((prev) => ({ ...prev, featureChangeSuggestion: event.target.value }))
              }></textarea>
          </div>
          <div className="mb-3">
            <p className="mb-1 unl-label-p">
              Have you faced any challenges when using our dashboard? How can we make it better for
              you?
            </p>
            <textarea
              className="w-100 form-control"
              name="feedback3"
              onChange={(event) =>
                setFeedback((prev) => ({ ...prev, userExperienceFeedback: event.target.value }))
              }></textarea>
          </div>
          <div>
            <input type="checkbox" checked={isChecked} name="approval" onChange={handleCheck} />
            <label>I am happy to be contacted about my feedback</label>
          </div>
          <div className="row justify-content-center mt-4">
            <div className="col-md-5">
              <button
                type="submit"
                className="btn btn-primary uniBtnPurple"
                disabled={isChecked ? false : true}>
                Submit
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default Feedback;
