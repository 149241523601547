import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import { fetchApplicationsData, fetchCostforApps } from '../service/apiServices';
import { useAzureData } from '../context/AzureContext';
import PlaceHolder from './PlaceHolder';
import parse from 'html-react-parser';
import { errorMessages } from '../utilities/constants';
import './FinOps.css';
import { sumOfCost } from '../utilities/helper';
type App = {
  application_id: number;
  application_name: string;
};

type Records = {
  application_id: number;
  application_name: string;
  client_request_id: string;
  model_id: string;
  total_cost: string;
  total_tokens: string;
  usage_date: string;
  model_name: string;
  client_name: string;
};
type AppCost = {
  records: Records[];
};
const FinOps = () => {
  let serialNumber = 0;
  const today = new Date();
  const lastDate = new Date();
  lastDate.setDate(today.getDate() - 1);
  const aweekAgo = new Date();
  aweekAgo.setDate(today.getDate() - 7);
  const [startDate, setStartDate] = useState<Date>(aweekAgo);
  const [endDate, setEndDate] = useState<Date>(lastDate);
  const [startDateFormatted, setStartDateFormatted] = useState(
    aweekAgo.toLocaleDateString('en-CA')
  );
  const [endDateFormatted, setEndDateFormatted] = useState(lastDate.toLocaleDateString('en-CA'));
  const [applicationData, setApplicationData] = useState<App[] | null>(null);
  const [selectedApp, setSelectedApp] = useState(null);
  const [appCost, setAppCost] = useState<AppCost | null>(null);
  const [errorMessage, setErrorMessage] = useState<any>(null);

  const { apiToken, isAdmin } = useAzureData();
  const navigate = useNavigate();
  const fetchCostforAllApp = async () => {
    try {
      const result = await fetchCostforApps(
        { startDate: startDateFormatted, endDate: endDateFormatted },
        apiToken
      );
      setAppCost(result);
    } catch (error: any) {
      setErrorMessage(error);
    }
  };
  useEffect(() => {
    const fetchAppData = async () => {
      try {
        const response = await fetchApplicationsData(apiToken);
        setApplicationData(response);
      } catch (error: any) {
        setErrorMessage(error);
      }
    };
    if (!isAdmin) {
      navigate('/dashboard');
      return;
    }
    fetchAppData();
    fetchCostforAllApp();
  }, [apiToken]);

  useEffect(() => {
    const fetchCostforApp = async () => {
      try {
        const result = await fetchCostforApps(
          { startDate: startDateFormatted, endDate: endDateFormatted, appId: selectedApp },
          apiToken
        );
        setAppCost(result);
      } catch (error: any) {
        setErrorMessage(error);
      }
    };
    if (startDate && endDate && !selectedApp) fetchCostforAllApp();
    else if (selectedApp && selectedApp !== 'ALL') fetchCostforApp();
    else if (selectedApp && selectedApp === 'ALL') fetchCostforAllApp();
  }, [startDate, endDate, selectedApp]);

  const handleAppChange = (id: any) => {
    setSelectedApp(id);
  };
  const handleRangeSelection = ([newStartDate, newEndDate]: any[]) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setStartDateFormatted(newStartDate?.toLocaleDateString('en-CA'));
    setEndDateFormatted(newEndDate?.toLocaleDateString('en-CA'));
  };

  const renderDatePicker = () => {
    return (
      <DatePicker
        maxDate={lastDate}
        minDate={new Date('2024-10-01')}
        showIcon
        selected={endDate}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        onChange={handleRangeSelection}
        className="date-picker"
      />
    );
  };
  const appWiseCost = appCost?.records?.map((app) => parseFloat(app.total_cost));
  const renderCostTable = () => (
    <div className="row g-2 row-cols-1 row-cols-md-1 mb-3 text-center">
      <div className="col">
        <div className="card mb-4 rounded-3 shadow-sm">
          <div className="card-header py-3">
            <h6 className="my-0 ">Cost By App</h6>
          </div>
          {appCost && appCost.records.length > 0 && (
            <div className="card-body table-responsive">
              <div className="table-container-cost">
                <table className="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Application</th>
                      <th>Model</th>
                      <th>Usage Date</th>
                      <th>Cost</th>
                    </tr>
                  </thead>
                  <tbody>
                    {appCost?.records?.map((cost, index) => (
                      <tr key={serialNumber}>
                        <td>{++serialNumber}</td>
                        <td>{cost.client_name}</td>
                        <td>{cost.model_name}</td>
                        <td>{cost.usage_date}</td>
                        <td>{cost.total_cost}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td style={{fontWeight:'bold'}}>Total</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      {appWiseCost &&  <td style={{fontWeight:'bold'}}> {sumOfCost(appWiseCost)}</td>}
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
  if (errorMessage)
    return (
      <PlaceHolder
        message={parse(
          errorMessage.message === 'UNAUTHORIZED'
            ? errorMessages.tokenExpired
            : errorMessages.serverError
        )}
      />
    );

  return (
    <>
      <div className="d-flex justify-content-end flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">
        <div className="mb-2 mb-md-0 float-start mx-2 form-group">
          <p
            className="mb-1"
            style={{
              color: '#797979',
              fontSize: '0.875rem',
              fontWeight: 400,
              fontStyle: 'Unilever Shilling'
            }}>
            Select Date Range
          </p>
          {renderDatePicker()}
        </div>
        <div className="mb-2 mb-md-0 float-start mx-4 form-group">
          <p
            className="mb-1"
            style={{
              color: '#797979',
              fontSize: '0.875rem',
              fontWeight: 400,
              fontStyle: 'Unilever Shilling'
            }}>
            Select Application
          </p>
          <select
            defaultValue={'ALL'}
            className="form-select"
            style={{ minWidth: '17.5rem', fontSize: '0.875rem' }}
            onChange={(e) => handleAppChange(e.target.value)}>
            <option value="ALL">ALL</option>
            {applicationData?.map((data) => (
              <option key={data.application_id} value={data.application_id}>
                {data.application_name}
              </option>
            ))}
          </select>
        </div>
      </div>
      {renderCostTable()}
    </>
  );
};

export default FinOps;
