import { useState } from 'react';
import Feedback from './Feedback';
import './Footer.css';
const Footer = () => {
  const [showFeedback, setShowFeedback] = useState(false);
  const handleClick = (e: any) => {
    e.preventDefault();
setShowFeedback(!showFeedback);
  };
  return (
    <>
      <footer className="footer mt-auto py-3 uni-bg-blue uni-footer">
        <div className="footer-container">
          <a
            href="https://unilever.sharepoint.com/sites/AI/SitePages/AI%20Foundation.aspx"
            target="_blank"
            className="footer-link">
            About Us
          </a>
          <a href="mailto:dl_unilever_ai_foundation@unilever.com" className="footer-link">
            Contact Us
          </a>
          <a onClick={(e) => handleClick(e)} className="footer-link">
            Send Feedback
          </a>
          <a
            href="https://unilever.sharepoint.com/sites/AI/SitePages/AI%20Foundation.aspx"
            target="_blank"
            className="footer-link">
            Need Help?
          </a>
        </div>
        <Feedback
          show={showFeedback}
          closeModal={() => {
            const timer = setTimeout(() => setShowFeedback(false), 1000);
            return () => clearTimeout(timer);
          }}
        />
      </footer>
    </>
  );
};

export default Footer;
