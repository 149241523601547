// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-container {
  font-size: 1rem;
}

.leaderboard .nav-link:hover {
  background-color: #C7C7C7;
}

.leaderboard .nav-tabs {
  border-bottom: 1px solid #C7C7C7;
}



th {
  font-weight: 600 !important;
  word-wrap: break-word;
  
}

`, "",{"version":3,"sources":["webpack://./src/components/Leaderboard.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,gCAAgC;AAClC;;;;AAIA;EACE,2BAA2B;EAC3B,qBAAqB;;AAEvB","sourcesContent":[".button-container {\n  font-size: 1rem;\n}\n\n.leaderboard .nav-link:hover {\n  background-color: #C7C7C7;\n}\n\n.leaderboard .nav-tabs {\n  border-bottom: 1px solid #C7C7C7;\n}\n\n\n\nth {\n  font-weight: 600 !important;\n  word-wrap: break-word;\n  \n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
