import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import AiFoundation from './view/AIview';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { AzureContextProvider } from './context/AzureContext';

function App() {
  return (
    <>
      <AzureContextProvider>
        <AuthenticatedTemplate>
          <Routes>
            <Route path="/" element={<AiFoundation page="dashboard" />} />
            <Route path="/dashboard" element={<AiFoundation page="dashboard" />} />
            <Route path="/rai" element={<AiFoundation page="rai" />} />
            <Route path="/leaderboard" element={<AiFoundation page="leaderboard" />} />
            <Route path="/promptlibrary" element={<AiFoundation page="promptlibrary" />} />
            <Route path="/promptplayground" element={<AiFoundation page="promptplayground" />} />
            <Route path="/finops" element={<AiFoundation page="finops" />} />
          </Routes>
        </AuthenticatedTemplate>
      </AzureContextProvider>
    </>
  );
}

export default App;
