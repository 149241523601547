// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-container-cost{
    max-height: 53.125rem;
    overflow-y: auto;
}


thead th{
    position: sticky;
    top:0;
 
    background-color: #f1f1f1;
}
.date-picker{
    border-radius: '8px';
}

/* thead, tfoot {
    display: table;
    width: 100%;
    table-layout: fixed;
} */
tfoot {
    position: sticky;
    bottom: 0;
    background-color: #f1f1f1;
    
}
tfoot td {
    border-top: 2px solid #ddd;
}`, "",{"version":3,"sources":["webpack://./src/components/FinOps.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,gBAAgB;AACpB;;;AAGA;IACI,gBAAgB;IAChB,KAAK;;IAEL,yBAAyB;AAC7B;AACA;IACI,oBAAoB;AACxB;;AAEA;;;;GAIG;AACH;IACI,gBAAgB;IAChB,SAAS;IACT,yBAAyB;;AAE7B;AACA;IACI,0BAA0B;AAC9B","sourcesContent":[".table-container-cost{\n    max-height: 53.125rem;\n    overflow-y: auto;\n}\n\n\nthead th{\n    position: sticky;\n    top:0;\n \n    background-color: #f1f1f1;\n}\n.date-picker{\n    border-radius: '8px';\n}\n\n/* thead, tfoot {\n    display: table;\n    width: 100%;\n    table-layout: fixed;\n} */\ntfoot {\n    position: sticky;\n    bottom: 0;\n    background-color: #f1f1f1;\n    \n}\ntfoot td {\n    border-top: 2px solid #ddd;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
